import { IExpiresAuthResponse, ISamlAuthResponse } from 'models/auth/auth.model';
import { SAML_SSO_CODE_VERIFIER_LOCAL_STORAGE_KEY, SAML_SSO_REDIRECT_URL } from 'constants/auth/samlAuth.const';
import { AuthManagerService } from 'services/auth/authManager.service';
import { getQuery, getCookie, deleteCookie, H3Event } from 'h3';
import { isEmpty } from 'lodash-es';
import { AuthHelper } from 'utils/authHelper.util';
import { NitroFetchOptions } from 'nitropack';
import { EQueryParam } from 'shared/enums/queryParam.enum';

export class SsoAuthManagerService extends AuthManagerService {

  static async startSsoAuth(
    successLoginRedirectUrl?: string,
    email?: string,
  ): Promise<string> {
    if (process.server) {
      return;
    }
    const redirectUrl = (await SsoAuthManagerService.requestAuth(successLoginRedirectUrl || window?.location.href, email))?.redirectUrl;
    redirectUrl && window?.open(redirectUrl, '_self');

    return redirectUrl;
  }

  private static async requestAuth(
    redirectUrl?: string,
    email?: string,
    provider?: string,
    fetchOptions: NitroFetchOptions<string> = {},
  ): Promise<ISamlAuthResponse> {
    return await AuthHelper.fetch<ISamlAuthResponse>(
      '/api/v1/saml/login',
      {
        method: 'POST',
        body: { redirectUrl, email, provider },
        ...fetchOptions,
      },
      true,
    );
  }

  static async startServerSsoAuth(
    event: H3Event,
    redirectUrl?: string,
    provider?: string,
  ): Promise<ISamlAuthResponse | undefined> {
    if (process.client) {
      return;
    }

    return await SsoAuthManagerService.requestAuth(redirectUrl, undefined, provider, {
      onResponse(context) {
        const cookie = context?.response?.headers?.get('set-cookie');
        if (cookie) {
          cookie?.split?.(',')?.map((value) => setPageResponseHeader('set-cookie', value, event));
        }
      },
    });
  }

  static async initSsoLogin(event: H3Event, nuxtApp, publicConfig): Promise<void> {
    if (!process.server) {
      return;
    }

    try {
      const query = getQuery(event);
      const {
        code, codeVerifier, redirectUrl,
      } = SsoAuthManagerService.getValuesAfterLoginRedirect(event);
      if (code && codeVerifier && redirectUrl) {
        await AuthHelper.fetch<IExpiresAuthResponse>('/api/v1/saml/callback', {
          method: 'POST',
          // TODO 14.06.2023 разобраться почему дублируются поля на стенде, но не дублируются локально
          body: {
            code: this.getLastArrayElement(code),
            redirectUrl: this.getLastArrayElement(redirectUrl),
            codeVerifier: this.getLastArrayElement(codeVerifier),
          },
          async onResponse(context) {
            const authCookies = context?.response?.headers?.get('set-cookie');

            if (authCookies) {
              const indexRefreshToken = authCookies.indexOf(publicConfig?.refreshTokenName);
              setPageResponseHeader('set-cookie', authCookies.substring(0, indexRefreshToken), event);
              setPageResponseHeader('set-cookie', authCookies.substring(indexRefreshToken), event);
            }
          },
        });
      }

      if (code || codeVerifier || redirectUrl) {
        await this.clearStateCallbackFn(event);
        nextTick(() => nuxtApp.$router.replace({ query: this.clearQueryState(query) }));
      }
    } catch (error) {
      console.error('Error - initSsoLogin: ', error);
    }
  }

  static async clearStateCallbackFn(event: H3Event): Promise<void> {
    if (!process.server) {
      return;
    }

    deleteCookie(event, SAML_SSO_CODE_VERIFIER_LOCAL_STORAGE_KEY);
    deleteCookie(event, SAML_SSO_REDIRECT_URL);
  }

  static clearQueryState(query: Record<string, unknown>): Record<string, unknown> | null {
    /**
     * Удаляем meta информацию по SSO из query.
     * Сделано так, чтобы не очищать нужные query параметры.
     * Например, при редиректе на заказ (?id=123) с авторизацией перед входом.
     */
    delete query[EQueryParam.SamlSsoCode];
    delete query[EQueryParam.SamlSsoSessionState];
    delete query[EQueryParam.Auth];

    return isEmpty(query) ? null : query;
  }

  static async ssoLogin(
    code: string,
    redirectUrl: string,
    codeVerifier: string,
    successCallbackFn?: () => void,
    errorCallbackFn?: (error) => void,
    isNeedCheckSupplierRedirect = true,
  ) {
    await this.startLogin(
      async () => await AuthHelper.fetch<IExpiresAuthResponse>(
        '/api/v1/saml/callback',
        { method: 'GET', params: { code, redirectUrl, codeVerifier } },
      ),
      successCallbackFn,
      errorCallbackFn,
      isNeedCheckSupplierRedirect,
    );
  }

  static getValuesAfterLoginRedirect(event: H3Event) {
    const query = getQuery(event);
    const code = query[EQueryParam.SamlSsoCode];
    const codeVerifier = getCookie(event, SAML_SSO_CODE_VERIFIER_LOCAL_STORAGE_KEY);
    const redirectUrl = getCookie(event, SAML_SSO_REDIRECT_URL);

    return {
      code,
      codeVerifier,
      redirectUrl,
    };
  }

  private static getLastArrayElement<T>(element:T|Array<T>): T {
    return Array.isArray(element)
      ? element[element.length - 1]
      : element;
  }
}
