<template>
  <Modal
    :model-value="modelValue"
    click-to-close
    :title-text="modalTitle ? modalTitle : 'Введите новый пароль'"
    ssr
    :z-index="10"
    hide-footer
    width="574px"
    :max-height="448"
    header-class="password-recovery-header"
    classes="modal fixed-bottom-modal bottom-0 mm-recovery-password"
    @ok="onSubmit"
    @close="$emit('close')"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <div class="mm-recovery-password__tip mm-body-regular-s">
      Пароль должен содержать от 8 символов, прописные (A-Z) и строчные (a-z) буквы латинского алфавита, минимум одну цифру, минимум один
      символ !”№;%:?*()_+-=#$%^&'
    </div>
    <div class="mm-recovery-password__fields-container">
      <div class="mm-recovery-password__field-wrapper">
        <PasswordField
          v-model="form[EValidationField.Password].value.value"
          class="mm-recovery-password__field"
          :placeholder="options?.passwordFieldPlaceholder ?? 'Новый пароль'"
          autocomplete="off"
          :custom-error="passwordFieldError"
        />
      </div>

      <div class="mm-recovery-password__field-wrapper">
        <PasswordField
          v-model="form[EValidationField.RepeatPassword].value.value"
          class="mm-recovery-password__field"
          :placeholder="options?.confirmFieldPlaceholder ?? 'Подтвердить пароль'"
          autocomplete="off"
          :custom-error="repeatPasswordFieldError"
        />
      </div>
    </div>

    <LoaderButton
      button-text="Подтвердить"
      :disabled="isConfirmButtonDisabled"
      :loader="isLoading"
      @confirm="onSubmit"
    />
  </Modal>
</template>

<script lang="ts" setup>
import Modal from 'shared/components/modals/Modal.vue';
import PasswordField from 'shared/components/PasswordField.vue';
import { FieldContext, useField, useForm } from 'vee-validate';
import { EValidatorsMessages } from 'shared/enums/validators.enum';
import { Validators } from 'shared/utils/validators.util';
import Loader from 'shared/utils/loaderHelper.util';
import Notificator from 'shared/services/notificator.service';
import { AccountApiService } from 'services/api/accountApi.service';
import { IPasswordModalOptions } from 'shared/models/auth/auth.model';

enum EValidationField {
  Password = 'password',
  RepeatPassword = 'repeatPassword',
}

const props = defineProps<{
  token: string;
  modelValue: boolean;
  modalTitle: string;
  options: IPasswordModalOptions;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'close'): void;
  (e: 'ok'): void;
}>();

const validationSchema = {
  [EValidationField.Password]: [Validators.required(), Validators.password(EValidatorsMessages.PasswordSafetyRequirements)],
  [EValidationField.RepeatPassword]: [Validators.required(), Validators.password(EValidatorsMessages.PasswordSafetyRequirements)],
};

const isConfirmButtonDisabled = computed(() => !form[EValidationField.Password].value.value?.length || !form[EValidationField.RepeatPassword].value.value?.length);

const { validate } = useForm({ validationSchema });

const form: Record<EValidationField, FieldContext<string>> = {
  [EValidationField.Password]: useField(EValidationField.Password),
  [EValidationField.RepeatPassword]: useField(EValidationField.RepeatPassword),
};

const passwordFieldError = ref<string>();
const repeatPasswordFieldError = ref<string>();
const isLoading = Loader.getReactiveInstance();

async function validateBeforeSubmit(): Promise<boolean> {
  const { valid } = await validate();

  passwordFieldError.value = form[EValidationField.Password].errorMessage.value;
  repeatPasswordFieldError.value = form[EValidationField.RepeatPassword].errorMessage.value;

  if (valid && form.password.value.value !== form.repeatPassword.value.value) {
    passwordFieldError.value = EValidatorsMessages.PasswordsNotMatch;
    repeatPasswordFieldError.value = EValidatorsMessages.PasswordsNotMatch;
    return false;
  }

  return valid;
}

async function onSubmit(): Promise<void> {
  try {
    isLoading.activate();
    const valid = await validateBeforeSubmit();

    if (!valid) {
      return;
    }

    if (props.options?.submitFn) {
      await props.options?.submitFn?.(form.password.value.value, props.token);
    }
    else {
      await AccountApiService.confirmPasswordChange(form.password.value.value, props.token);
    }

    emits('ok');
  } catch (error) {
    Notificator.showDetachedNotification(props.options?.errorNotification ?? 'Возникла ошибка при попытке восстановлении пароля, попробуйте, пожалуйста, ещё раз');
  } finally {
    isLoading.deactivate();
  }
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.modal) {
  .modal-content {
    border: none;

  }

  .mm-recovery-password__field-wrapper:not(:last-child) {
    margin-bottom: 12px;
  }

  .mm-password-field__field {
    padding-bottom: 0;
  }

  .password-recovery-header {
    margin-bottom: 20px !important;

    .btn {
      padding: 8px 8px 8px 16px;
    }
  }

  .password-recovery-footer {
    margin-top: 32px !important;
  }
}

:deep(.modal .modal-header .btn .close-icon path) {
  fill: $text-dark-green !important;
}

.mm-recovery-password {
  &__tip {
    color: $text-black;
    line-height: 20px;
  }

  &__fields-container {
    margin: 40px 0 32px;
  }
}

@media screen and (max-width: 767px) {
  .mm-recovery-password {
    &__tip {
      line-height: 20px;
    }

    &__fields-container {
      margin-top: 20px;
    }
  }
}
</style>
