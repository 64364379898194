<template>
  <Modal
    :model-value="modelValue"
    click-to-close
    title-text="Восстановление пароля"
    ssr
    :z-index="10"
    hide-cancel-button
    hide-ok-button
    width="574px"
    :hide-footer="isSuccessfullLinkSent"
    :disabled-ok-button="isDisabled.value"
    :disabled-cancel-button="isDisabled.value"
    header-class="password-recovery-header"
    footer-class="password-recovery-footer"
    classes="modal fixed-bottom-modal bottom-0"
    @ok="onSubmit"
    @close="$emit('update:modelValue', false)"
  >
    <div v-if="!isSuccessfullLinkSent">
      <div class="password-recovery-text">
        Введите свою почту, чтобы мы прислали
        ссылку для сброса пароля
      </div>

      <TextField
        v-model="emailField.value.value"
        placeholder="Электронная почта"
        icon-disabled
        :validation-field="emailField"
      />
      <LoaderButton
        class="password-recovery-ok-button"
        button-text="Восстановить"
        :loader="isDisabled"
        @confirm="onSubmit"
      />
    </div>
    <div
      v-else
      class="password-recovery-message"
    >
      На почту {{ emailField.value.value }} отправлена ссылка для сброса пароля. Проверьте почту и пройдите по ссылке из
      письма, чтобы задать новый пароль.
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import Modal from 'shared/components/modals/Modal.vue';
import TextField from 'shared/components/TextField.vue';
import { useField, useForm, useIsFormValid } from 'vee-validate';
import { EValidatorsMessages } from 'shared/enums/validators.enum';
import { Validators } from 'shared/utils/validators.util';
import Disabler from 'shared/utils/disablerHelper.util';
import Notificator from 'shared/services/notificator.service';
import { AccountApiService } from 'services/api/accountApi.service';

enum EPasswordRecoveryFormField {
  Email = 'email',
}

enum EPasswordRecoveryStages {
  InsertNewEmail,
  SuccessfullLinkSent,
}

defineProps<{
  modelValue: boolean
}>();

defineEmits<{
  (e: 'update:modelValue', value: boolean);
}>();

const validationSchema = {
  [EPasswordRecoveryFormField.Email]: [Validators.required(EValidatorsMessages.Required), Validators.yupEmail(EValidatorsMessages.Email)],
};

const form = useForm({
  validationSchema,
});

const emailField = useField<string>(EPasswordRecoveryFormField.Email);
const isFormValid = useIsFormValid();
const isDisabled = Disabler.getReactiveInstance();
const currentRecoveryStage = ref<EPasswordRecoveryStages>(EPasswordRecoveryStages.InsertNewEmail);
const isSuccessfullLinkSent = computed(() => currentRecoveryStage.value === EPasswordRecoveryStages.SuccessfullLinkSent);

async function onSubmit(): Promise<void> {
  try {
    isDisabled.activate();
    await form.validate();

    if (!isFormValid.value) {
      return;
    }

    await AccountApiService.requestPasswordRestore(emailField.value.value);
    currentRecoveryStage.value = EPasswordRecoveryStages.SuccessfullLinkSent;
  } catch (error) {
    if (error.data?.statusCode === 404 && error.data?.data.detail?.startsWith('Not found user with')) {
      Notificator.showDetachedNotification('Введенный адрес почты не совпадает с указанным в профиле');
      return;
    };
    Notificator.showDetachedNotification('Ошибка отправки письма для восстановления пароля');
  } finally {
    isDisabled.deactivate();
  }
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

:deep(.password-recovery-header) {
  margin-bottom: 20px !important;
}

:deep(.password-recovery-footer) {
  margin-top: 0 !important;

  .btn {
    font-weight: 400 !important;
  }
}

:deep(.mm-input__error) {
  margin-top: 2px;
}

:deep(.mm-input--is-invalid) {
  .mm-input__icon {
    top: 29px !important;
  }
}

:deep(.modal .modal-header .btn .close-icon path) {
  fill: $text-dark-green !important;
}

.password-recovery {
  &-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $password-recovery-modal-text-c;
    width: 280px;
    white-space: normal;
    margin-bottom: 40px;
  }

  &-message {
    padding: 0 24px 20px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  &-ok-button {
    margin-top: 14px;
  }
}
</style>
