<template>
  <Modal
    :model-value="modelValue"
    click-to-close
    :title-text="isEnterEmail ? 'Войти в аккаунт' : 'Введите пароль'"
    :z-index="10000"
    cancel-button-text="Забыли пароль?"
    :ok-button-text="isEnterEmail ? 'Продолжить' : 'Войти'"
    :ok-btn-id="isEnterEmail ? 'continue_button' : 'authorization_button'"
    :disabled-ok-button="disabledOkButton"
    width="574px"
    footer-buttons-direction="column-reverse"
    header-class="mm-login-modal__header"
    title-class="mm-login-modal__title"
    classes="modal fixed-bottom-modal bottom-0"
    class="login-modal"
    close-modal-id="close_button"
    @before-open="onBeforeOpen"
    @opened="onBeforeOpen"
    @close="onClose"
    @click-outside="onClose"
    @ok="isEmailStage ? onContinueLogin() : onLogin()"
  >
    <div class="mm-login-modal__header">
      <template v-if="currentStage !== ELoginStages.EnterEmail">
        <div class="mm-login-modal__helper-text">
          Введите пароль для {{ email }}
          <button
            class="change-email__link btn btn-text"
            @click="onChangeEmail"
          >
            Изменить
          </button>
        </div>
      </template>
    </div>

    <form class="mm-login-modal__form">
      <div :class="{ 'd-none': currentStage !== ELoginStages.EnterEmail }">
        <TextField
          v-model="emailField.value.value"
          :validation-field="emailField"
          autocomplete="email"
          label="Электронная почта"
          icon-path=""
          is-only-submit-validate
          :type="ETextFieldTypes.Email"
          :start-autofocus="isEnterEmail"
          input-spec-id="email_input"
          @enter="onContinueLogin"
        />
      </div>

      <div :class="{ 'd-none': currentStage !== ELoginStages.EnterPassword }">
        <PasswordField
          v-model="passwordField.value.value"
          :start-autofocus="isEnterPassword"
          :validation-field="passwordField"
          is-only-submit-validate
          label="Пароль"
          input-spec-id="password_input"
          @enter="onLogin"
        />
      </div>
    </form>
    <template #cancel-button>
      <button
        class="btn btn-text forgot-password-btn"
        :class="{ 'd-none': currentStage !== ELoginStages.EnterPassword }"
        :disabled="isProcessingLogin.value"
        @click="onForgetPassword()"
      >
        Забыли пароль?
      </button>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import TextField from 'shared/components/TextField.vue';
import PasswordField from 'shared/components/PasswordField.vue';
import { useField, useForm } from 'vee-validate';
import { EValidatorsMessages } from 'shared/enums/validators.enum';
import { Validators } from 'shared/utils/validators.util';
import Modal from 'shared/components/modals/Modal.vue';
import { AuthManagerService } from 'services/auth/authManager.service';
import Notificator from 'shared/services/notificator.service';
import { SsoAuthManagerService } from 'services/auth/ssoAuthManager.service';
import { nextTick } from 'vue';
import Loader from 'shared/utils/loaderHelper.util';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { FORBIDDEN_ERROR_CODE, NOT_FOUND_ERROR_CODE } from 'shared/constants/error.const';
import { ETextFieldTypes } from 'shared/enums/textFieldTypes.enum';

enum ELoginStages {
  EnterEmail,
  EnterPassword,
}

enum ELoginFormFields {
  Email = 'email',
  Password = 'password',
}

const props = defineProps<{
  modelValue: boolean;
  isNeedCheckSupplierRedirect?: boolean;
}>();

const emits = defineEmits<{
  (e: 'closeLoginModal', value: boolean): void;
  (e: 'clickPasswordRecovery'): void;
  (e: 'update:modelValue', value: boolean): void;
  (e: 'successLogin'): void;
}>();

const validationSchema = {
  [ELoginFormFields.Email]: [Validators.required(EValidatorsMessages.Required)],
  [ELoginFormFields.Password]: [Validators.required(EValidatorsMessages.Required)],
};

const form = useForm({
  validationSchema,
});

const isProcessingLogin = Loader.getReactiveInstance();
const currentStage = ref(ELoginStages.EnterEmail);
const email = ref('');
const emailField = useField<string>(ELoginFormFields.Email);
const passwordField = useField<string>(ELoginFormFields.Password);
/**
 * Был добавлен костыль в виде isOpen.
 * Так как модалка почему-то остаётся в дереве
 */
const isOpen = ref(true);

const isEnterEmail = computed(() => currentStage.value === ELoginStages.EnterEmail);
const isEmailStage = computed(() => currentStage.value === ELoginStages.EnterEmail);
const isEnterPassword = computed(() => currentStage.value === ELoginStages.EnterPassword);

const disabledOkButton = computed(
  () => isProcessingLogin.value,
);

function onBeforeOpen() {
  isOpen.value = true;
}

function onChangeEmail(): void {
  currentStage.value = ELoginStages.EnterEmail;
}

function onClose(): void {
  emitCloseLoginModal(false);
}

function onForgetPassword(): void {
  validationSchema[ELoginFormFields.Email] = [];
  emailField.value.value = '';
  emits('update:modelValue', false);
  emits('clickPasswordRecovery');
}

function emitCloseLoginModal(value: boolean): void {
  emits('closeLoginModal', value);
  emits('update:modelValue', value);
  // nextTick чтобы не прыгал контент
  nextTick(() => isOpen.value = false);
}

async function onContinueLogin() {
  if (isProcessingLogin.value) {
    return;
  }

  validationSchema[ELoginFormFields.Email] = [Validators.required(EValidatorsMessages.Required), Validators.yupEmail(EValidatorsMessages.Email)];
  emailField.setTouched(true);
  await emailField.validate();
  if (!(await emailField.validate()).valid) {
    return;
  }

  email.value = emailField.value.value;

  if (!form.errors.value.email) {
    await startSsoAuth();
  }
}

async function startSsoAuth(): Promise<void> {
  isProcessingLogin.activate();

  try {
    const redirectUrl = await SsoAuthManagerService.startSsoAuth(undefined, email.value);
    if (redirectUrl) {
      return;
    }

    currentStage.value = ELoginStages.EnterPassword;
    isProcessingLogin.deactivate();
  } catch (error){
    if ([FORBIDDEN_ERROR_CODE, NOT_FOUND_ERROR_CODE].includes(error?.data?.statusCode)) {
      currentStage.value = ELoginStages.EnterPassword;
    } else {
      clientSentry.captureException(error, { extra: { component: 'LoginModal', function: 'startSsoAuth' }});
    }

    currentStage.value = ELoginStages.EnterPassword;
    isProcessingLogin.deactivate();
  }
}

async function onLogin() {
  passwordField.setTouched(true);
  if (!(await form.validate()).valid) {
    return;
  }

  isProcessingLogin.activate();
  try {
    await AuthManagerService.login(email.value, passwordField.value.value, successCallbackFn, errorCallbackFn, props.isNeedCheckSupplierRedirect);
  } catch (error) {
    console.error(error);
  } finally {
    isProcessingLogin.deactivate();
  }
}

async function successCallbackFn(): Promise<void> {
  emits('successLogin');
  emitCloseLoginModal(false);
}

function errorCallbackFn(error): void {
  if (error.response?._data?.data?.detail?.includes('Email is not verified')) {
    passwordField.setErrors('Произошла ошибка. E-mail не подтвержден');

    return;
  }

  if (error.response?._data?.data?.detail?.includes('Invalid credentials')) {
    passwordField.setErrors('Неверный логин или пароль');

    return;
  }

  passwordField.setErrors('Произошла ошибка аутентификации');
  Notificator.showDetachedNotification('Возникла ошибка при попытке авторизации, попробуйте, пожалуйста, ещё раз');
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

@import 'shared/assets/styles/base/common/variables';

$root: '.mm-login-modal';

:deep(.vfm--inset) {
  cursor: default;
}

.login-modal {
  :deep(#{$root}__helper-text) {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text-black;

    .change-email__link {
      margin-left: 4px;
      padding: 0;
      color: $link;
      font-weight: 500;
    }
  }

  :deep(#{$root}__header) {
    margin-bottom: 0 !important;
  }

  :deep(#{$root}__title) {
    font-weight: 500 !important;
  }

  :deep(#{$root}__form) {
    margin-top: 40px;

    .mm-password-field__container {
      svg {
        path {
          fill: $light-green;
        }
      }
    }
  }

  :deep(.modal-footer) {
    margin-top: 14px;

    .forgot-password-btn {
      padding: 16px 0 0;
      font-weight: 400;
    }
  }

  :deep(.modal-header .btn .close-icon path) {
    fill: $text-dark-green !important;
  }

  :deep(.btn-primary:disabled) {
    opacity: 1;
  }
}

.mm-login-modal {
  &-loader {
    &__wrapper {
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__loader {
      position: static;
    }
  }
}

@media only screen and (max-width: 1279px) {
  .login-modal {
    :deep(.modal-header) {
      .btn-secondary {
        padding: 8px;

        svg {
          margin: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .login-modal {
    :deep(#{$root}__header) {
      .btn-secondary {
        svg {
          width: initial;
          height: initial;
          margin-left: 0;
        }
      }
    }

    :deep(#{$root}__form) {
      margin-top: 32px;
    }

    :deep(.modal-footer) {
      margin-top: 10px;
    }
  }
}
</style>
